<template>
	<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
		<div class="drawer-wrapper">
			<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
				<div class="font-level-3-bold">Task Details</div>
				<v-spacer></v-spacer>
				<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
				<v-btn tile depressed color="white--text" class="blue darken-4">Edit</v-btn>
			</v-flex>
			<div class="drawer-content pt-5 px-5">
				<div class="mb-3">
					<div class="d-flex pt-1">
						<label class="fw-500">Title</label>
					</div>
					<v-text-field depressed hide-details outlined v-model="task.title"></v-text-field>
				</div>
				<div class="mb-3">
					<div class="d-flex pt-1">
						<label class="fw-500">Details</label>
					</div>
					<v-textarea rows="4" hide-details outlined v-model="task.detail"> </v-textarea>
				</div>
				<div class="mb-3">
					<div class="d-flex pt-1">
						<label class="fw-500">Active Date</label>
					</div>
					<v-text-field depressed hide-details outlined v-model="task.active_date"></v-text-field>
				</div>
				<div class="mb-3">
					<div class="d-flex pt-1">
						<label class="fw-500">All Day</label>
					</div>
					<v-autocomplete
						:items="alldayList"
						depressed
						hide-details
						outlined
						v-model="task.allday"
					></v-autocomplete>
				</div>
				<div class="mb-3">
					<div class="d-flex pt-1">
						<label class="fw-500">Priority</label>
					</div>
					<v-autocomplete
						:items="priorityList"
						depressed
						hide-details
						outlined
						v-model="task.priority"
					></v-autocomplete>
				</div>
			</div>
		</div>
	</v-navigation-drawer>
</template>
<script>
export default {
	name: "eventDetails",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		dataEvent: {
			type: Object,
		},
	},
	watch: {
		dataEvent: {
			deep: true,
			handler(param) {
				this.task.title = param.name;
			},
		},
	},
	data() {
		return {
			task: {
				title: null,
				detail: null,
				active_date: null,
				allday: null,
				priority: null,
			},
			alldayList: [],
			priorityList: [],
		};
	},
	mounted() {
		console.log(this.dataEvent);
	},
};
</script>
